import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { enqueueSnackbar } from 'notistack';

import { eliminarPromocionesCompraGana } from '../../../services/administrador/compraGana';

ModalEliminar.propTypes = {
    open: PropTypes.bool,
    cerrarModal: PropTypes.func,
};

function ModalEliminar({ open, cerrarModal }) {
    const [loadingBoton, setLoadingBoton] = useState(false);

    const eliminarPromociones = async () => {
        setLoadingBoton(true);

        const result = await eliminarPromocionesCompraGana();

        setLoadingBoton(false);
        cerrarModal();

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: result.status ? 10000 : 10000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <Dialog onClose={cerrarModal} open={open} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4" gutterBottom>
                    Eliminar Promociones
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Esta seguro de eliminar todas las promociones de "Compra y Gana"?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cerrarModal}>Cancelar</Button>
                <LoadingButton variant="contained" loading={loadingBoton} color='error' onClick={eliminarPromociones}>
                    Eliminar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ModalEliminar;
