import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function obtenerGrupos() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/grupos/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function crearGrupo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/grupos/crear`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function editarGrupo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/grupos/editar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function eliminarGrupo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/grupos/eliminar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}