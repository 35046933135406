import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import Label from '../../../components/LabelText';

// utils
import { fPhoneNumber } from '../../../utils/formatNumber';

// context
import useUsuario from '../../../hooks/administrador/useUsuario';

import CabeceraTabla from './CabeceraTabla';
import BuscadorUsuarios from './BuscadorUsuarios';
// import FormCliente from './FormUsuario';

// Servicios
import { obtenerUsuarios, eliminarUsuario as eliminar } from '../../../services/administrador/usuarios';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'grupo', label: 'Grupo', alignRight: false },
    { id: 'usuario', label: 'Usuario', alignRight: false },
    { id: 'nombre', label: 'Nombre', alignRight: false },
    { id: 'apellido', label: 'Apellido', alignRight: false },
    { id: 'correo', label: 'Correo', alignRight: false },
    { id: 'compania', label: 'Compañia', alignRight: false },
    { id: 'telefono', label: 'Teléfono', alignRight: false },
    { id: 'activo', label: 'Estatus', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.fields.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AdministradorUsuarios() {
    const { onChangeUsuario } = useUsuario();
    const navigate = useNavigate();
    const theme = useTheme();
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsuarios();
    }, []);

    const getUsuarios = async () => {
        setLoading(true);
        const result = await obtenerUsuarios();

        if (result.status) {
            setUsuarios(result.data);
        }
        setLoading(false);
    };

    const handleOpenMenu = (event, data) => {
        const dataUsuario = data.fields;
        dataUsuario.id = data.pk;
        setUsuario(dataUsuario);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setUsuario({});
        setOpen(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const crearUsuario = () => {
        navigate('/administrador/usuarios/crear', { replace: true });
    }

    const editarUsuario = () => {
        onChangeUsuario(usuario);
        navigate('/administrador/usuarios/editar', { replace: true });
    }

    const abrirVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(true);
    }

    const cerrarVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(false);
    }

    const eliminarUsuario = async () => {
        setLoadingBoton(true);
        
        const result = await eliminar(usuario);

        if (result.status) {
            getUsuarios();
        }

        setLoadingBoton(false);
        setOpenEliminar(false);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usuarios.length) : 0;
    const filteredUsers = applySortFilter(usuarios, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Usuarios | Scorpion </title>
            </Helmet>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Venta de eliminar Usuario */}
            <Dialog onClose={cerrarVentanaEliminar} open={openEliminar} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Eliminar Usuario
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de eliminar el Usuario?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaEliminar}>Cancelar</Button>
                    <LoadingButton variant="contained" color="error" loading={loadingBoton} onClick={eliminarUsuario}>
                        Eliminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Usuarios
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={crearUsuario}>
                        Nuevo Usuario
                    </Button>
                </Stack>

                <Card>
                    <BuscadorUsuarios filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((usuario) => {
                                            const { grupo, username, first_name, last_name, email, company, phone, active } = usuario.fields;

                                            return (
                                                <TableRow hover key={usuario.pk}>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {grupo}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {username}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {first_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {last_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {email}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {company}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {fPhoneNumber(phone)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                            color={(active !== 1 && 'error') || 'success'}
                                                        >
                                                            {active === 1 ? "Activo" : "Inactivo"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, usuario)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Intente con otro cliente.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={usuarios.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editarUsuario}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar Usuario
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={abrirVentanaEliminar}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Eliminar Usuario
                </MenuItem>
            </Popover>
        </>
    );
}
