import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes/router';
// theme
import { SettingsProvider } from './context/SettingsContext';
import ThemeProvider from './theme';
// components
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <SettingsProvider>
            <ThemeProvider>
                <ThemePrimaryColor>
                    <HelmetProvider>
                        <BrowserRouter>
                            <ScrollToTop />
                            <StyledChart />
                            <Router />
                        </BrowserRouter>
                    </HelmetProvider>
                </ThemePrimaryColor>
            </ThemeProvider>
        </SettingsProvider>
    );
}
