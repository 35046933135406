import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// context
import useGrupo from '../../../hooks/administrador/useGrupo';

import CabeceraTabla from './CabeceraTabla';
import BuscadorGrupos from './BuscadorGrupos';

// Servicios
import { obtenerGrupos, eliminarGrupo as eliminar } from '../../../services/administrador/grupos';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'nombre', label: 'Nombre', alignRight: false },
    { id: 'descripcion', label: 'Descripción', alignRight: false },
    { id: 'jerarquia', label: 'Jerarquía', alignRight: false },
    { id: 'color', label: 'Color', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.fields.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AdministradorGrupos() {
    const { onChangeGrupo } = useGrupo();
    const navigate = useNavigate();
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState({});
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getGrupos();
    }, []);

    const getGrupos = async () => {
        setLoading(true);
        const result = await obtenerGrupos();
        
        if (result.status) {
            setGrupos(result.data);
        }
        setLoading(false);
    };

    const handleOpenMenu = (event, data) => {
        const dataGrupo = data.fields;
        dataGrupo.id = data.pk;
        setGrupo(dataGrupo);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setGrupo({});
        setOpen(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const crearGrupo = () => {
        navigate('/administrador/grupos/crear', { replace: true });
    }

    const editarGrupo = () => {
        onChangeGrupo(grupo);
        navigate('/administrador/grupos/editar', { replace: true });
    }

    const abrirVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(true);
    }

    const cerrarVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(false);
    }

    const eliminarGrupo = async () => {
        setLoadingBoton(true);
        
        const result = await eliminar(grupo);

        if (result.status) {
            getGrupos();
        }

        setLoadingBoton(false);
        setOpenEliminar(false);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupos.length) : 0;
    const filteredUsers = applySortFilter(grupos, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Grupos | Scorpion </title>
            </Helmet>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Venta de eliminar Grupo */}
            <Dialog onClose={cerrarVentanaEliminar} open={openEliminar} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Eliminar Grupo
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de eliminar el Grupo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaEliminar}>Cancelar</Button>
                    <LoadingButton variant="contained" color="error" loading={loadingBoton} onClick={eliminarGrupo}>
                        Eliminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Grupos
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={crearGrupo}>
                        Nuevo Grupo
                    </Button>
                </Stack>

                <Card>
                    <BuscadorGrupos filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((grupo) => {
                                            const { name, description, bgcolor, jerarquia } = grupo.fields;

                                            return (
                                                <TableRow hover key={grupo.pk}>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {jerarquia}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{
                                                            height: "30px",
                                                            width: "30px",
                                                            borderRadius: "50%",
                                                            display: "inline-block",
                                                            backgroundColor: `${bgcolor}`,
                                                        }}/>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, grupo)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Intente con otro grupo.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={grupos.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editarGrupo}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar Grupo
                </MenuItem>

                <MenuItem onClick={abrirVentanaEliminar} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Eliminar Grupo
                </MenuItem>
            </Popover>
        </>
    );
}
