import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Container,
    Button,
    Grid,
    Stack,
    TextField,
    InputAdornment,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// context
import useUsuario from '../../../hooks/administrador/useUsuario';

// services
import { obtenerGrupos } from '../../../services/administrador/grupos';
import { crearUsuario, editarUsuario } from '../../../services/administrador/usuarios';

// ----------------------------------------------------------------------

FormUsuario.propTypes = {
    isEdit: PropTypes.bool,
    usuario: PropTypes.object
};

export default function FormUsuario({ isEdit, usuario }) {
    const navigate = useNavigate();
    const { onChangeUsuario } = useUsuario();
    const [animacionCarga, setAnimacionCarga] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [form, setForm] = useState({
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        phone: "",
        active: "",
        id_grupo: "",
    });

    useEffect(() => {
        getGrupos();

        if (Object.keys(usuario).length !== 0) {
            setForm({
                id: usuario.id,
                username: usuario.username,
                password: usuario.password,
                first_name: usuario.first_name,
                last_name: usuario.last_name,
                email: usuario.email,
                company: usuario.company,
                phone: usuario.phone.slice(3), // Quitar +52
                active: usuario.active,
                id_grupo: usuario.id_grupo,
            });
        }
    }, [usuario]);

    const getGrupos = async () => {
        setAnimacionCarga(true);
        const result = await obtenerGrupos();

        if (result.status) {
            setGrupos(result.data);
        }

        setAnimacionCarga(false);
    }

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const handleChangeNumber = (e) => {
        const { name } = e.target;
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');

        if (onlyNums.length < 11) {
            setForm({
                ...form,
                [name]: onlyNums,
            });
        }
    }

    const cancelar = () => {
        onChangeUsuario({});
        navigate('/administrador/usuarios', { replace: true });
    }

    const crearEditarUsuario = async () => {
        setLoadingBoton(true);
        console.log("FORM", form);

        // Checar que los campos no esten vacios
        let vacio = false;
        Object.keys(form).forEach((key) => {
            if (form[key] === "" || form[key] === null) {
                vacio = true;
            }
        });

        // Si estan vacios regresar
        if (vacio) {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor introduzca los datos', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            return;
        }

        let result = {}
        
        if (!isEdit) {
            result = await crearUsuario(form);
        } else {
            result = await editarUsuario(form);
        }
        
        setLoadingBoton(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            onChangeUsuario({});
            setTimeout(() => {
                navigate('/administrador/usuarios', { replace: true });
            }, 3000);
        }
    }

    return (
        <Container>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h4" mb={3}>
                {isEdit ? 'Editar Usuario' : 'Crear Usuario'}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Nombre de Usuario"
                                    variant="outlined"
                                    fullWidth
                                    value={form.username}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    value={form.password}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="first_name"
                                    name="first_name"
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    value={form.first_name}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="last_name"
                                    name="last_name"
                                    label="Apellido"
                                    variant="outlined"
                                    fullWidth
                                    value={form.last_name}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Correo"
                                    variant="outlined"
                                    fullWidth
                                    value={form.email}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="company"
                                    name="company"
                                    label="Comapañia"
                                    variant="outlined"
                                    fullWidth
                                    value={form.company}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="phone"
                                    name="phone"
                                    label="Teléfono"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">(+52)</InputAdornment>,
                                    }}
                                    value={form.phone}
                                    onChange={handleChangeNumber}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="simple-grupo-label">Grupo</InputLabel>
                                    <Select
                                        labelId="simple-grupo-label"
                                        id="id_grupo"
                                        name="id_grupo"
                                        label="Grupo"
                                        value={form.id_grupo}
                                        onChange={handleChangeText}
                                    >
                                        {
                                            grupos.map((grupo) => (
                                                <MenuItem key={grupo.pk} value={grupo.pk}>{grupo.fields.description}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <Grid item xs={12} sm={5.9} spacing={0.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="simple-active-label">Activo</InputLabel>
                                        <Select
                                            labelId="simple-active-label"
                                            id="active"
                                            name="active"
                                            label="Activo"
                                            value={form.active}
                                            onChange={handleChangeText}
                                        >
                                            <MenuItem value="1">Si</MenuItem>
                                            <MenuItem value="0">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Stack>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ marginRight: '15px' }}
                                    variant="outlined"
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton variant="contained" loading={loadingBoton} onClick={crearEditarUsuario}>
                                    {!isEdit ? 'Crear Usuario' : 'Guardar Cambios'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
