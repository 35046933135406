import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Container,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';

// import ColorPicker from '../../../components/color-picker/ColorPicker';

// context
import useSucursal from '../../../hooks/administrador/useSucursal';

// services
import { editarSucursalPassword as editar } from '../../../services/administrador/sucursales';

// ----------------------------------------------------------------------

FormSucursal.propTypes = {
    sucursal: PropTypes.object
};

export default function FormSucursal({ sucursal }) {
    const navigate = useNavigate();
    const { onChangeSucursal } = useSucursal();
    const [showPassword, setShowPassword] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [form, setForm] = useState({
        id: "",
        num_sucursal: "",
        nombre_sucursal: "",
        password: "",
    });

    useEffect(() => {
        if (Object.keys(sucursal).length !== 0) {
            setForm({
                id: sucursal.id,
                num_sucursal: sucursal.num_sucursal,
                nombre_sucursal: sucursal.nombre_sucursal,
                password: sucursal.password,
            });
        }
    }, [sucursal]);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const cancelar = () => {
        onChangeSucursal({});
        navigate('/administrador/sucursales', { replace: true });
    }

    const editarSucursalPassword = async () => {
        setLoadingBoton(true);

        // Checar que los campos no esten vacios
        let vacio = false;
        if (form.password === "" || form.password === null) {
            vacio = true;
        }

        // Si estan vacios regresar
        if (vacio) {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor introduzca la contraseña', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            return;
        }

        const result = await editar(form);
        
        setLoadingBoton(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            onChangeSucursal({});
            setTimeout(() => {
                navigate('/administrador/sucursales', { replace: true });
            }, 3000);
        }
    }

    return (
        <Container>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Typography variant="h4" mb={3}>
                {'Editar Sucursal'}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Número de Sucursal"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={form.num_sucursal}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="nombre_sucursal"
                                    name="nombre_sucursal"
                                    label="Nombre de Sucursal"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={form.nombre_sucursal}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <Grid item xs={12} sm={5.9} spacing={0.5}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label={`Contraseña`}
                                    type={showPassword ? 'text' : 'password'}
                                    value={form.password}
                                    fullWidth
                                    onChange={handleChangeText}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                </Grid>
                            </Stack>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ marginRight: '15px' }}
                                    variant="outlined"
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton variant="contained" loading={loadingBoton} onClick={editarSucursalPassword}>
                                    {'Guardar Cambios'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
