import { Page, Text, View, Document, StyleSheet, Tspan, Font } from '@react-pdf/renderer';

import RobotoRegular from '../theme/fonts/Roboto-Regular.ttf';
import RobotoBold from '../theme/fonts/Roboto-Bold.ttf';
import RobotoBlack from '../theme/fonts/Roboto-Black.ttf';

Font.register({
    family: 'Roboto', fonts: [
        {
            src: RobotoRegular,
            fontWeight: 400
        },
        {
            src: RobotoBold,
            fontWeight: 700
        },
        {
            src: RobotoBlack,
            fontWeight: 900
        }
    ]
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '0 10',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        margin: '0 auto'
    },
    header: {
        marginTop: 10,
        textAlign: 'center',
        fontSize: 10,
        lineHeight: 1.5,
    },
    footer: {
        marginBottom: 10,
        textAlign: 'center',
        fontSize: 8
    },
    footerPadding: {
        padding: '10 0'
    },
    messages: {
        fontSize: 10,
        textTransform: 'uppercase',
        textAlign: 'center',
        margin: '5 0',
        lineHeight: 1.5
    },
    offers: {
        textAlign: 'left',
        fontSize: 8,
        marginBottom: 10
    },
    offerTitle: {
        fontSize: 16,
        marginBottom: 5,
        marginTop: 5,
        padding: '3 0',
        textAlign: 'center',
        backgroundColor: '#000',
        color: '#fff'
    },
    bolded: {
        fontWeight: 'black'
    },
    upper: {
        textTransform: 'uppercase'
    },
    level: {
        fontSize: 16
    },
    mt: {
        marginTop: 5
    },
    bTop: {
        borderTop: '1px dashed #000'
    },
    bTopBottom: {
        borderTop: '1px dashed #000',
        borderBottom: '1px dashed #000'
    }
});

const PdfTicket = ({ ticketData }) => {
    const { numeroTarjeta, numeroCliente, fecha, nombreCliente, nivelCliente, sucursal, tituloTicket, promocionesTicket } = ticketData;
    return (
        <Document>
            <Page size={[227, 792]} style={styles.page}>
                <View style={[styles.header]}>
                    <Text style={styles.bolded}><Tspan style={styles.upper}>Numero de tarjeta:</Tspan> {numeroTarjeta}</Text>
                    <Text style={styles.bolded}><Tspan style={styles.upper}>Número de cliente:</Tspan> {numeroCliente}</Text>
                    <Text>{fecha}</Text>
                    <Text style={styles.bolded}><Tspan style={styles.upper}>Nombre:</Tspan> {nombreCliente}</Text>
                    <Text style={[styles.bolded, styles.level]}>{nivelCliente}</Text>
                    <Text><Tspan style={styles.upper}>Suc</Tspan> {sucursal}</Text>
                </View>
                <View style={[styles.messages, styles.bolded]}>
                    {
                        Object.values(tituloTicket).map((titulo, i) => <Text key={i}>{titulo}</Text>)
                    }
                </View>
                <View style={styles.offers}>
                    {
                        promocionesTicket.map((promocion, i) => {
                            const { nombre, articulos } = promocion;
                            return (
                                <div key={i}>
                                    <Text style={[styles.offerTitle, styles.bolded]}>{nombre}</Text>
                                    {
                                        articulos.map((articulo, i) => {
                                            if (articulo.startsWith('GRATIS')) {
                                                const freeString = articulo.substring(0, 6).trim();
                                                const productString = articulo.substring(6).trim();
                                                return (
                                                    <Text style={styles.mt} key={i}>
                                                        <Tspan style={styles.bolded}>{freeString}</Tspan>
                                                        {'\n'}{productString}
                                                    </Text>
                                                );
                                            } if(articulo.startsWith('EN')) {
                                                return (
                                                    <Text style={styles.mt} key={i}>
                                                        {articulo}
                                                    </Text>
                                                );
                                            }
                                            const porcentage = articulo.split('-')[0].trim();
                                            const offer = articulo.split('-')[1].trim();
                                            return <Text style={styles.mt} key={i}><Tspan style={styles.bolded}>{Number(porcentage.slice(0, -1)).toFixed(0)}%</Tspan> - {offer}</Text>;
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </View>
                <View style={[styles.footer]}>
                    <View style={[styles.footerPadding, styles.bTop]}>
                        <Text >ATENTOS A TUS NECESIDADES AL NUMERO</Text>
                        <Text >5747-9300</Text>
                    </View>
                    <View style={[styles.footerPadding, styles.bTopBottom]}>
                        <Text >
                            APLICAN RESTRICCIONES / LIMITADO A 10 ARTICULOS POR PROMOCION / DESCUENTOS SUJETOS A CAMBIOS SIN PREVIO AVISO / ALCOHOL Y CIGARROS: PROHIBIDA LA VENTA A MENORES DE 18 AÑOS / OFERTAS VIGENTES PARA EL DIA DE HOY
                        </Text>
                    </View>
                </View>
            </Page >
        </Document >
    )
}

export default PdfTicket;