import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function obtenerUsuarios() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/usuarios/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function crearUsuario(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/usuarios/crear`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function editarUsuario(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/usuarios/editar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function eliminarUsuario(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/usuarios/eliminar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}