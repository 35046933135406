// component
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    {
        subheader: 'general',
        items: [
            {
                title: 'Usuarios',
                path: '/general/usuarios',
                icon: icon('usuarios'),
            },
            {
                title: 'Tickets',
                path: '/general/tickets',
                icon: icon('tickets'),
            },
            {
                title: 'Historial',
                path: '/general/historial',
                icon: icon('historial'),
            },
            {
                title: 'Sucursales',
                path: '/general/sucursales',
                icon: icon('sucursales'),
            },
            {
                title: 'Proveedores',
                path: '/general/proveedores',
                icon: icon('proveedores'),
            },
            {
                title: 'Articulos',
                path: '/general/articulos',
                icon: icon('articulos'),
            },
        ],
    },
];

export default navConfig;
