export function loginAuth(token, tipoUsuario, usuario) {
    localStorage.setItem('TOKEN_USUARIO_SCORPION_TICKETS', token);
    localStorage.setItem('TIPO_USUARIO_SCORPION_TICKETS', tipoUsuario.toLowerCase());
    localStorage.setItem('USUARIO_SCORPION_TICKETS', JSON.stringify(usuario));
}

export function logoutAuth() {
    localStorage.removeItem('TOKEN_USUARIO_SCORPION_TICKETS');
    localStorage.removeItem('TIPO_USUARIO_SCORPION_TICKETS');
    localStorage.removeItem('USUARIO_SCORPION_TICKETS');
}

export function isLogin() {
    if (localStorage.getItem('TOKEN_USUARIO_SCORPION_TICKETS')) {
        return true;
    }

    return false;
}

export function typeUser() {
    if (localStorage.getItem('TIPO_USUARIO_SCORPION_TICKETS')) {
        return localStorage.getItem('TIPO_USUARIO_SCORPION_TICKETS');
    }
    return '/';
}
