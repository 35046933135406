import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    IconButton,
    Popover,
    MenuItem
} from '@mui/material';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import CabeceraTabla from './CabeceraTabla';
import BuscadorIdArticulo from './BuscadorIdArticulo';
import BuscadorNombre from './BuscadorNombre';
import FormImagen from './FormImagen';

// Servicios
import { obtenerArticulos } from '../../../services/administrador/articulos';

// ----------------------------------------------------------------------

const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const TABLE_HEAD = [
    { id: 'imagen', label: 'Imagen', alignRight: false },
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'nombre', label: 'Nombre', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, queryId, queryName) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (queryId) {
        return filter(array, (_user) => _user.pk.toString().toLowerCase().indexOf(queryId.toLowerCase()) !== -1);
    }
    if (queryName) {
        return filter(array, (_user) => _user.fields.des1.toLowerCase().indexOf(queryName.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AdministradorArticulos() {
    const [articulos, setArticulos] = useState([]);
    const [articulo, setArticulo] = useState({});
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterIdArticulo, setFilterIdArticulo] = useState('');
    const [filterNombre, setFilterNombre] = useState('');
    const [loading, setLoading] = useState(false);
    const [ventanaImagen, setVentanaImagen] = useState(false);

    useEffect(() => {
        getArticulos();
    }, []);

    const getArticulos = async () => {
        setLoading(true);
        const result = await obtenerArticulos();
        
        if (result.status) {
            setArticulos(result.data);
        }
        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterById = (event) => {
        setPage(0);
        setFilterIdArticulo(event.target.value);
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterNombre(event.target.value);
    };

    const handleOpenMenu = (event, data) => {
        const dataArticulo = data.fields;
        dataArticulo.art = data.pk;
        setArticulo(dataArticulo);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setArticulo({});
        setOpen(null);
    };

    const abrirVentanaImagen = async () => {
        setOpen(null);
        setVentanaImagen(true);
    }

    const cerrarVentanaImagen = () => {
        setOpen(null);
        setVentanaImagen(false);
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articulos.length) : 0;
    const filteredUsers = applySortFilter(articulos, getComparator(order, orderBy), filterIdArticulo, filterNombre);
    const isNotFound = !filteredUsers.length && !!filterIdArticulo || !filteredUsers.length && !!filterNombre;

    return (
        <>
            <Helmet>
                <title> Articulos | Scorpion </title>
            </Helmet>

            {/* Modal para Subir la Imagen */}
            <FormImagen open={ventanaImagen} cerrarForm={cerrarVentanaImagen} articulo={articulo} getArticulos={getArticulos} />

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Articulos
                    </Typography>
                </Stack>

                <Card>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <BuscadorIdArticulo filterName={filterIdArticulo} onFilterName={handleFilterById} />
                        <BuscadorNombre filterName={filterNombre} onFilterName={handleFilterByName} />
                    </Stack>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((articulo) => {
                                            const { imagen, des1 } = articulo.fields;

                                            return (
                                                <TableRow hover key={articulo.pk}>
                                                    <TableCell align="center">
                                                    <img
                                                        src={`${REACT_APP_API_URL_IMAGES}${imagen}`}
                                                        alt=""
                                                        width="70" height="70"
                                                    />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {articulo.pk}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {des1}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, articulo)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {(isNotFound && !loading) && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para los filtros seleccionadoss &nbsp;
                                                        <br /> Intente con otros.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={abrirVentanaImagen}>
                    <Iconify icon={'eva:image-fill'} sx={{ mr: 2 }} />
                    Subir Imagen
                </MenuItem>
            </Popover>
        </>
    );
}
