import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// material
import { Container } from '@material-ui/core';

// context
import useUsuario from '../../../hooks/administrador/useUsuario';

// components
import FormUsuario from './FormUsuario';

// ----------------------------------------------------------------------

export default function CrearEditarUsuario() {
    const { usuario } = useUsuario();
    const { pathname } = useLocation();
    const isEdit = pathname.includes('editar');
    const currentUser = usuario;

    return (
        <>
            <Helmet>
                <title> {!isEdit ? 'Crear Usuario' : 'Editar Usuario'} | Scorpion </title>
            </Helmet>
        
            <Container maxWidth="xl">
                <FormUsuario isEdit={isEdit} usuario={currentUser} />
            </Container>
        </>
    );
}
