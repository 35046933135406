import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader } from 'react-drag-drop-files';
import { enqueueSnackbar } from 'notistack';

import { crearUsuariosURL } from '../../../services/administrador/usuariosURL';

const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const fileTypes = ["CSV"];

FormArchivo.propTypes = {
    open: PropTypes.bool,
    cerrarForm: PropTypes.func,
};

function FormArchivo({ open, cerrarForm }) {
    const [form, setForm] = useState({
        nombre: "",
        archivo: "",
    });
    const [animacionCarga, setAnimacionCarga] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);

    const selectFile = (event) => {
        setForm({
            nombre: event.name,
            archivo: event,
        });
    }

    const subirArchivo = async () => {
        setLoadingBoton(true);
        // Llenar FormData con el form
        if (!form.archivo) {
            setLoadingBoton(false);
            return;
        }

        const data = new FormData();
        data.append("archivo", form.archivo);

        setLoadingBoton(false);
        cerrarForm();
        setAnimacionCarga(true);

        const result = await crearUsuariosURL(data);
        
        setAnimacionCarga(false);
        setForm({});

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: result.status ? 10000 : 10000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <>
            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Subir Archivo
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Archivo para guardar tus Usuarios
                    </DialogContentText>

                    <Grid item container justifyContent="center" mt={5}>
                        <FileUploader handleChange={(e) => selectFile(e)} name="file" types={fileTypes} />
                    </Grid>
                    <Grid container item justifyContent="center">
                        {
                            form.archivo
                            ?   <div style={{ textAlign: "center", marginTop: '25px' }}>
                                    <img key="archivo" src={`${REACT_APP_API_URL_IMAGES}public/excel-logo.png`} style={{ height: 125, width: 125 }} alt="Archivo"/>
                                    <Typography>{form.nombre}</Typography>
                                </div>
                            : ''
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarForm}>Cancelar</Button>
                    <LoadingButton variant="contained" loading={loadingBoton} onClick={subirArchivo}>
                        Subir
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default FormArchivo;
