import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
// hooks

const initialState = {
    grupo: {},
    onChangeGrupo: () => {},
};

const handlers = {
    SETGRUPO: (state, action) => {
        const { grupo } = action.payload;
        return {
            ...state,
            grupo
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const GrupoContext = createContext(initialState);

AdministradorGrupoProvider.propTypes = {
    children: PropTypes.node,
};

function AdministradorGrupoProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangeGrupo = (grupo) => {
        dispatch({
            type: 'SETGRUPO',
            payload: { grupo }
        });
    }

    return (
        <GrupoContext.Provider
            value={{
                ...state,
                onChangeGrupo,
            }}
        >
            {children}
        </GrupoContext.Provider>
    );
}

export { AdministradorGrupoProvider, GrupoContext };
