import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

// ----------------------------------------------------------------------

export default function Select() {
    return {
        MuiSelect: {
            defaultProps: {
                IconComponent: ExpandMoreRoundedIcon,
            },

            styleOverrides: {
                root: {},
            },
        },
    };
}
