import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, TableHead, styled } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import useSettings from '../../../hooks/useSettings';

CabeceraTabla.propTypes = {
    headLabel: PropTypes.array,
};

export default function CabeceraTabla({ headLabel }) {
    const { setColor, themeColor } = useSettings();

    // Estilo para la cabecera
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: setColor.main,
            color: themeColor !== 'orange' ? theme.palette.common.white : theme.palette.common.black,
            fontSize: 16,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    
    return (
        <TableHead>
            <TableRow>
                {headLabel.map((headCell) => (
                    <StyledTableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        {headCell.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
