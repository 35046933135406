import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function crearPromocionesCompraGanaExcel(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/compra-gana/crear-excel`, payload, {timeout: 1800000}));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function eliminarPromocionesCompraGana() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/compra-gana/eliminar`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerFechaArchivo() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/compra-gana/fecha-archivo`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
