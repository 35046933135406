import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader } from 'react-drag-drop-files';
import { enqueueSnackbar } from 'notistack';

import { crearPromocionesCompraGanaExcel } from '../../../services/administrador/compraGana';

const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const fileTypes = ["CSV"];

FormArchivo.propTypes = {
    open: PropTypes.bool,
    cerrarForm: PropTypes.func,
};

function FormArchivo({ open, cerrarForm }) {
    const [form, setForm] = useState({
        nombre: "",
        archivo: "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const selectFile = (event) => {
        setForm({
            nombre: event.name,
            archivo: event,
        });
    }

    const subirArchivo = async () => {
        setLoadingBoton(true);
        // Llenar FormData con el form
        if (!form.archivo) {
            setLoadingBoton(false);
            return;
        }

        const data = new FormData();
        data.append("archivo", form.archivo);

        const result = await crearPromocionesCompraGanaExcel(data);

        setForm({});
        setLoadingBoton(false);
        cerrarForm();

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: result.status ? 10000 : 10000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4" gutterBottom>
                    Subir Archivo
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Archivo para guardar las promociones Compra y Gana
                </DialogContentText>

                <Grid item container justifyContent="center" mt={5}>
                    <FileUploader handleChange={(e) => selectFile(e)} name="file" types={fileTypes} />
                </Grid>
                <Grid container item justifyContent="center">
                    {
                        form.archivo
                        ?   <div style={{ textAlign: "center", marginTop: '25px' }}>
                                <img key="archivo" src={`${REACT_APP_API_URL_IMAGES}public/excel-logo.png`} style={{ height: 125, width: 125 }} alt="Archivo"/>
                                <Typography>{form.nombre}</Typography>
                            </div>
                        : ''
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cerrarForm}>Cancelar</Button>
                <LoadingButton variant="contained" loading={loadingBoton} onClick={subirArchivo}>
                    Subir
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default FormArchivo;
