import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function obtenerArticulos() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/articulos/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function editarImagenArticulo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/articulos/editar-imagen`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
