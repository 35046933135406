// component
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    {
        subheader: 'administrador',
        items: [
            {
                title: 'Grupos',
                path: '/administrador/grupos',
                icon: icon('grupos'),
            },
            {
                title: 'Usuarios',
                path: '/administrador/usuarios',
                icon: icon('usuarios'),
            },
            {
                title: 'Usuarios URL',
                path: '/administrador/usuarios-url',
                icon: icon('usuarios-url'),
            },
            {
                title: 'Tickets',
                path: '/administrador/tickets',
                icon: icon('tickets'),
            },
            {
                title: 'Historial',
                path: '/administrador/historial',
                icon: icon('historial'),
            },
            {
                title: 'Sucursales',
                path: '/administrador/sucursales',
                icon: icon('sucursales'),
            },
            {
                title: 'Proveedores',
                path: '/administrador/proveedores',
                icon: icon('proveedores'),
            },
            {
                title: 'Articulos',
                path: '/administrador/articulos',
                icon: icon('articulos'),
            },
            // {
            //     title: 'Promociones Puntos',
            //     path: '/administrador/promociones-puntos',
            //     icon: icon('promociones'),
            // },
            // {
            //     title: 'Pesos Lealtad',
            //     path: '/administrador/pesos-lealtad',
            //     icon: icon('pesos-lealtad'),
            // },
            {
                title: 'Compra y Gana',
                path: '/administrador/compra-gana',
                icon: icon('compra-gana'),
            },
        ],
    },
];

export default navConfig;
