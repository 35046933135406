import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader } from 'react-drag-drop-files';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { editarImagenProveedor } from '../../../services/administrador/proveedores';

const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const fileTypes = ["JPG", "JPEG", "PNG"];

FormImagen.propTypes = {
    open: PropTypes.bool,
    proveedor: PropTypes.array,
    cerrarForm: PropTypes.func,
    getProveedores: PropTypes.func,
};

function FormImagen({ open, cerrarForm, proveedor, getProveedores }) {
    const [form, setForm] = useState({
        nombre: "",
        imagen: "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const selectFile = (event) => {
        setForm({
            nombre: event.name,
            imagen: event,
        });
    }

    const subirImagen = async () => {
        setLoadingBoton(true);
        
        if (!form.imagen) {
            setLoadingBoton(false);
            return;
        }

        // Llenar FormData con el form
        const data = new FormData();

        // eslint-disable-next-line
        Object.keys(proveedor).forEach(function(key) {
            data.append(key, proveedor[key]);
        });

        data.append("imagen", form.imagen);

        const result = await editarImagenProveedor(data);
        
        if (result.status) {
            getProveedores();
        }

        setForm({});
        setLoadingBoton(false);
        cerrarForm();

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Subir Imagen
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Imagen para guardar en el Proveedor "{proveedor.descripcion}"
                    </DialogContentText>

                    <Grid item container justifyContent="center" mt={5}>
                        <FileUploader handleChange={(e) => selectFile(e)} name="file" types={fileTypes} />
                    </Grid>
                        {
                            form.imagen
                            ?   <Grid item container justifyContent="center" mt={2}>
                                    <img key="Imagen"
                                        src={form.imagen instanceof Object 
                                            ? URL.createObjectURL(form.imagen) 
                                            : `${REACT_APP_API_URL_IMAGES}${form.imagen}`} 
                                        style={{ height: 150, width: 150 }}
                                        alt="Imagen" />
                                <Grid item container justifyContent="center" mt={2}>
                                    <Typography>{form.nombre}</Typography>
                                </Grid>
                                </Grid>
                            :   ''
                        }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarForm}>Cancelar</Button>
                    <LoadingButton variant="contained" loading={loadingBoton} onClick={subirImagen}>
                        Subir
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default FormImagen;
