import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// material
import { Container } from '@material-ui/core';

// context
import useGrupo from '../../../hooks/administrador/useGrupo';

// components
import FormGrupo from './FormGrupo';

// ----------------------------------------------------------------------

export default function CrearEditarGrupo() {
    const { grupo } = useGrupo();
    const { pathname } = useLocation();
    const isEdit = pathname.includes('editar');
    const currentUser = grupo;

    return (
        <>
            <Helmet>
                <title> {!isEdit ? 'Crear Grupo' : 'Editar Grupo'} | Scorpion </title>
            </Helmet>
        
            <Container maxWidth="xl">
                <FormGrupo isEdit={isEdit} grupo={currentUser} />
            </Container>
        </>
    );
}
