import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Grid,
    Stack,
    Container,
    Typography,
    Button,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';

// components
import ExcelIcon from '../../../components/excel-icon';
import Iconify from '../../../components/iconify';
import { fDateTime } from '../../../utils/formatTime';

import FormArchivo from './FormArchivo';
import ModalEliminar from './ModalEliminar';

import { obtenerFechaArchivo } from '../../../services/administrador/compraGana';

// ----------------------------------------------------------------------

export default function AdministradorCompraGana() {
    const [fecha, setFecha] = useState("");
    const [openForm, setOpenForm] = useState(false);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);

    useEffect(() => {
        getFechaArchivo();
    }, [])

    const getFechaArchivo = async () => {
        const result = await obtenerFechaArchivo();
        setFecha(result.data);
    }

    const abrirFormArchivo = () => {
        setOpenForm(true);
    }
    
    const cerrarFormArchivo = () => {
        setOpenForm(false);
    }

    const abrirModalEliminar = () => {
        setOpenModalEliminar(true);
    }
    
    const cerrarModalEliminar = () => {
        setOpenModalEliminar(false);
    }

    return (
        <>
            <Helmet>
                <title> Compra y Gana | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Compra y Gana
                    </Typography>
                </Stack>

                <Container maxWidth="lg">
                    
                    {/* Form Archivo */}
                    <FormArchivo open={openForm} cerrarForm={cerrarFormArchivo} />

                    {/* Modal para eliminar */}
                    <ModalEliminar open={openModalEliminar} cerrarModal={cerrarModalEliminar} />

                    <Card>
                        <Grid style={{ padding: '20px' }} mt={6} mb={6}>
                            <Stack justifyContent="space-between">
                                <Stack alignItems="flex-end" justifyContent="flex-end">
                                    <Button variant="contained" startIcon={<Iconify icon="eva:trash-2-fill" />} color='error' onClick={abrirModalEliminar}>
                                        Eliminar Promociones
                                    </Button>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" mt={2}>
                                    <Typography variant='h4'>Registra tus promociones de Compra y Gana</Typography>
                                    <Typography variant='body1' mt={7}>Registra las promociones de Compra y Gana para tus usuarios.</Typography>
                                    <Typography variant='body1' mb={7}>Ultimo archivo subido: {fDateTime(fecha)}.</Typography>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center">
                                    <Button variant='contained' onClick={abrirFormArchivo} startIcon={<ExcelIcon />}>
                                        Subir Compra y Gana
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Card>
                </Container>
            </Container>
        </>
    );
}
