import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
// hooks

const initialState = {
    usuario: {},
    onChangeUsuario: () => {},
};

const handlers = {
    SETUSUARIO: (state, action) => {
        const { usuario } = action.payload;
        return {
            ...state,
            usuario
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const UsuarioContext = createContext(initialState);

AdministradorUsuarioProvider.propTypes = {
    children: PropTypes.node,
};

function AdministradorUsuarioProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangeUsuario = (usuario) => {
        dispatch({
            type: 'SETUSUARIO',
            payload: { usuario }
        });
    }

    return (
        <UsuarioContext.Provider
            value={{
                ...state,
                onChangeUsuario,
            }}
        >
            {children}
        </UsuarioContext.Provider>
    );
}

export { AdministradorUsuarioProvider, UsuarioContext };
