import SvgIcon from '@mui/material/SvgIcon';


export default function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg 
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="18.000000pt"
                height="18.000000pt" 
                viewBox="0 0 50.000000 50.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                fill="#FFF" stroke="none">
                    <path d="M135 471 l-130 -26 0 -195 0 -195 135 -27 c74 -15 141 -27 148 -27 9
                    -1 12 55 12 249 0 232 -1 250 -17 249 -10 -1 -76 -13 -148 -28z m1 -163 l17
                    -33 15 33 c11 23 22 32 39 32 l24 0 -26 -45 -26 -44 26 -46 27 -45 -24 0 c-16
                    0 -30 10 -43 32 l-18 32 -16 -32 c-10 -22 -22 -32 -38 -32 -29 0 -29 2 3 50
                    l26 39 -26 46 -27 45 25 0 c19 0 30 -9 42 -32z"/>
                    <path d="M320 406 c0 -19 6 -37 13 -40 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13
                    -29 0 -13 6 -26 13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13 -29 0 -13 6 -26
                    13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -18 -13 -34 0 -16 6 -31 13 -34 10 -5 10
                    -7 0 -12 -7 -3 -13 -21 -13 -40 l0 -35 83 3 82 3 0 185 0 185 -82 3 -83 3 0
                    -35z m120 -46 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0
                    40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40
                    10 22 0 40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6
                    18 10 40 10 22 0 40 -4 40 -10z m0 -80 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40
                10 0 6 18 10 40 10 22 0 40 -4 40 -10z"/>
                </g>
            </svg>
        </SvgIcon>
    );
}
