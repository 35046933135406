// component
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    {
        subheader: 'usuario',
        items: [
            {
                title: 'Tickets',
                path: '/usuario/tickets',
                icon: icon('tickets'),
            },
            {
                title: 'Historial',
                path: '/usuario/historial',
                icon: icon('historial'),
            },
        ],
    },
];

export default navConfig;
