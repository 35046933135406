import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { format, parseISO } from 'date-fns';
// @mui
import {
    Card,
    Table,
    Grid,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Traducir el picker date a español
import esEs from 'date-fns/locale/es';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Scrollbar from '../../../components/scrollbar';

import CabeceraTabla from './CabeceraTabla';
// import BuscadorHistorial from './BuscadorHistorial';

// Servicios
import { obtenerHistorial } from '../../../services/administrador/tickets';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'ConsSesion', label: 'ConsSesion', alignRight: false },
    { id: 'Suc', label: 'Suc', alignRight: false },
    { id: 'IdKiosko', label: 'IdKiosko', alignRight: false },
    { id: 'Modulo', label: 'Modulo', alignRight: false },
    { id: 'CodBarras', label: 'CodBarras', alignRight: false },
    { id: 'Renglon', label: 'Renglon', alignRight: false },
    { id: 'CveLog', label: 'CveLog', alignRight: false },
    { id: 'Cte', label: 'Cte', alignRight: false },
    { id: 'Usuario', label: 'Usuario', alignRight: false },
    { id: 'FecReg', label: 'FecReg', alignRight: false },
    { id: 'HraReg', label: 'HraReg', alignRight: false },
    { id: 'UsrReg', label: 'UsrReg', alignRight: false },
];

const CSV_HEAD = ["ConsSesion", "Suc", "IdKiosko", "Modulo", "CodBarras", "Renglon", "CveLog", "Cte", "Usuario", "FecReg", "HraReg", "UsrReg"]

// ----------------------------------------------------------------------
// Icono de Excel
function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg 
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="18.000000pt"
                height="18.000000pt" 
                viewBox="0 0 50.000000 50.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                fill="#FFF" stroke="none">
                    <path d="M135 471 l-130 -26 0 -195 0 -195 135 -27 c74 -15 141 -27 148 -27 9
                    -1 12 55 12 249 0 232 -1 250 -17 249 -10 -1 -76 -13 -148 -28z m1 -163 l17
                    -33 15 33 c11 23 22 32 39 32 l24 0 -26 -45 -26 -44 26 -46 27 -45 -24 0 c-16
                    0 -30 10 -43 32 l-18 32 -16 -32 c-10 -22 -22 -32 -38 -32 -29 0 -29 2 3 50
                    l26 39 -26 46 -27 45 25 0 c19 0 30 -9 42 -32z"/>
                    <path d="M320 406 c0 -19 6 -37 13 -40 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13
                    -29 0 -13 6 -26 13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13 -29 0 -13 6 -26
                    13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -18 -13 -34 0 -16 6 -31 13 -34 10 -5 10
                    -7 0 -12 -7 -3 -13 -21 -13 -40 l0 -35 83 3 82 3 0 185 0 185 -82 3 -83 3 0
                    -35z m120 -46 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0
                    40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40
                    10 22 0 40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6
                    18 10 40 10 22 0 40 -4 40 -10z m0 -80 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40
                10 0 6 18 10 40 10 22 0 40 -4 40 -10z"/>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default function AdministradorHistorial() {
    const [historial, setHistorial] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [fechaFilterInicio, setFechaFilterInicio] = useState(null);
    const [fechaFilterFin, setFechaFilterFin] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getHistorial();
    }, []);

    const getHistorial = async () => {
        setLoading(true);
        const result = await obtenerHistorial();
        
        if (result.status) {
            setHistorial(result.data);
        }
        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const filterFecha = () => {
        const newHistorial = historial.filter((item) => {
            if (item.fields.fec_reg !== null) {
                const fechaItem = format(parseISO(item.fields.fec_reg), "yyyy-LL-dd");
                const fechaFiltInicio = format(fechaFilterInicio, "yyyy-LL-dd");
                const fechaFiltFin = format(fechaFilterFin, "yyyy-LL-dd");
                return fechaItem >= fechaFiltInicio && fechaItem <= fechaFiltFin;
            }

            return null;
        });

        return newHistorial;
    }

    const limpiarFiltro = () => {
        setFechaFilterInicio(null);
        setFechaFilterFin(null);
    }

    const download = (csvString, fileName = 'test.csv') => {
        // Creamos el elemento para hacer el trigger del download
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/octet-stream,' + encodeURIComponent(csvString));
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);  
    }

    const descargarCSV = () => {
        // Llenamos el CSV
        const csvStringObject = CSV_HEAD.join(',') + '\n' + filteredUsers.reverse().map(row => Object.values(row.fields).join(',')).join('\n');
        // Descargamos
        download(csvStringObject, 'Historial.csv');
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - historial.length) : 0;
    const filteredUsers = fechaFilterInicio !== null && fechaFilterFin !== null ? filterFecha() : historial;
    const isNotFound = !filteredUsers.length;

    return (
        <>
            <Helmet>
                <title> Historial | Scorpion </title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Historial de Impresiones
                    </Typography>
                </Stack>

                <Card>
                    <Grid style={{ padding: '15px' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                <Typography variant='body1' ml={1} mr={2}>DE:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esEs}>
                                    <DatePicker value={fechaFilterInicio} onChange={(newValue) => setFechaFilterInicio(newValue)} />
                                </LocalizationProvider>
                                <Typography variant='body1' ml={2} mr={2}>A:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esEs}>
                                    <DatePicker value={fechaFilterFin} onChange={(newValue) => setFechaFilterFin(newValue)} />
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                <Button variant='contained' style={{ marginRight: '15px' }} onClick={limpiarFiltro}>
                                    Limpiar Filtro
                                </Button>
                                <Button variant='contained' onClick={descargarCSV} startIcon={<ExcelIcon />}>
                                    Descargar CSV
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((grupo) => {
                                            // num_tarjeta, num_cliente, usuario, num_sucursal, nombre_sucursal, fecha
                                            const { cons_sesion, suc, id_kiosko, modulo, cod_barras, renglon, cve_log, cte, usuario, fec_reg, hra_reg, usr_reg } = grupo.fields;

                                            return (
                                                <TableRow hover key={grupo.pk}>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cons_sesion}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {suc}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {id_kiosko}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {modulo}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cod_barras}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {renglon}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cve_log}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cte}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {usuario}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {fec_reg}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {hra_reg}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {usr_reg}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {(isNotFound && !loading) && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para las fechas seleccionadas &nbsp;
                                                        {/* <strong>&quot;{fechaFilter}&quot;</strong>. */}
                                                        <br /> Intente con otras fechas.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </>
    );
}
