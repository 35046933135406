import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
// components
import Iconify from '../iconify/index';

// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '480px !important',
});

// ----------------------------------------------------------------------

AutocompleteData.propTypes = {
    sucursales: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default function AutocompleteData({ sucursales, handleChange }) {
    return (
        <Autocomplete
            sx={{ width: 480 }}
            autoHighlight
            popupIcon={null}
            PopperComponent={StyledPopper}
            options={sucursales}
            getOptionLabel={(sucursal) => sucursal.fields.nombre_sucursal}
            isOptionEqualToValue={(option, value) => option.fields.num_sucursal === value.fields.num_sucursal}
            onChange={(event, newValue) => {handleChange(newValue)}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Buscar Sucursal..."
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify
                                    icon={'eva:search-fill'}
                                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
}
