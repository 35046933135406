import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
// hooks

const initialState = {
    sucursal: {},
    onChangeSucursal: () => {},
};

const handlers = {
    SETSUCURSAL: (state, action) => {
        const { sucursal } = action.payload;
        return {
            ...state,
            sucursal
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const SucursalContext = createContext(initialState);

AdministradorSucursalProvider.propTypes = {
    children: PropTypes.node,
};

function AdministradorSucursalProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangeSucursal = (sucursal) => {
        dispatch({
            type: 'SETSUCURSAL',
            payload: { sucursal }
        });
    }

    return (
        <SucursalContext.Provider
            value={{
                ...state,
                onChangeSucursal,
            }}
        >
            {children}
        </SucursalContext.Provider>
    );
}

export { AdministradorSucursalProvider, SucursalContext };
