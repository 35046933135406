import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Container,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { SketchPicker } from 'react-color'
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// import ColorPicker from '../../../components/color-picker/ColorPicker';

// context
import useGrupo from '../../../hooks/administrador/useGrupo';

// services
import { crearGrupo, editarGrupo } from '../../../services/administrador/grupos';

// ----------------------------------------------------------------------

FormGrupo.propTypes = {
    isEdit: PropTypes.bool,
    grupo: PropTypes.object
};

export default function FormGrupo({ isEdit, grupo }) {
    const navigate = useNavigate();
    const { onChangeGrupo } = useGrupo();
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [form, setForm] = useState({
        name: "",
        description: "",
        bgcolor: "#ffffff",
        jerarquia: "",
    });

    useEffect(() => {
        if (Object.keys(grupo).length !== 0) {
            setForm({
                id: grupo.id,
                name: grupo.name,
                description: grupo.description,
                bgcolor: grupo.bgcolor,
                jerarquia: grupo.jerarquia,
            });
        }
    }, [grupo]);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const handleChangeColor = (color) => {
        setForm({
            ...form,
            bgcolor: color.hex,
        })
    }

    const cancelar = () => {
        onChangeGrupo({});
        navigate('/administrador/grupos', { replace: true });
    }

    const crearEditarGrupo = async () => {
        setLoadingBoton(true);

        // Checar que los campos no esten vacios
        let vacio = false;
        Object.keys(form).forEach((key) => {
            if (form[key] === "" || form[key] === null) {
                vacio = true;
            }
        });

        // Si estan vacios regresar
        if (vacio) {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor introduzca los datos', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            return;
        }

        let result = {}
        
        if (!isEdit) {
            result = await crearGrupo(form);
        } else {
            result = await editarGrupo(form);
        }
        
        setLoadingBoton(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            onChangeGrupo({});
            setTimeout(() => {
                navigate('/administrador/grupos', { replace: true });
            }, 3000);
        }
    }

    return (
        <Container>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Typography variant="h4" mb={3}>
                {isEdit ? 'Editar Grupo' : 'Crear Grupo'}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    value={form.name}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Descripción"
                                    variant="outlined"
                                    fullWidth
                                    value={form.description}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="bgcolor"
                                    name="bgcolor"
                                    label="Color"
                                    variant="outlined"
                                    fullWidth
                                    value={form.bgcolor}
                                    onChange={handleChangeText}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="simple-jerarquia-label">Jerarquía</InputLabel>
                                    <Select
                                        labelId="simple-jerarquia-label"
                                        id="jerarquia"
                                        name="jerarquia"
                                        label="Jerarquia"
                                        value={form.jerarquia}
                                        onChange={handleChangeText}
                                    >
                                        <MenuItem key="Administrador" value="Administrador">Administrador</MenuItem>
                                        <MenuItem key="General" value="General">General</MenuItem>
                                        <MenuItem key="Usuario" value="Usuario">Usuario</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <Grid item xs={12} sm={5.9} spacing={0.5}>
                                    <SketchPicker color={form.bgcolor} onChange={handleChangeColor} />
                                </Grid>
                            </Stack>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ marginRight: '15px' }}
                                    variant="outlined"
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton variant="contained" loading={loadingBoton} onClick={crearEditarGrupo}>
                                    {!isEdit ? 'Crear Grupo' : 'Guardar Cambios'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
