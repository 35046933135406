import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
// components
import Iconify from '../../components/iconify';

// services
import { login } from '../../services/login';

import { loginAuth } from '../../auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState({
        "usuario": "",
        "password": "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const handleChangeText = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const ingresar = async () => {
        setLoadingBoton(true);
        const result = await login(form);
        setLoadingBoton(false);

        enqueueSnackbar(result.mensaje, {
            variant: result.status ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            loginAuth(result.token, result.tipo_usuario, result.usuario);
            setForm({});
            setLoadingBoton(false);
            navigate(`/${result.tipo_usuario}`, { replace: true });
        }
    };

    return (
        <>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Stack spacing={3}>
                <TextField name="usuario" label="Usuario" onChange={handleChangeText} />

                <TextField
                    name="password"
                    label={`Contraseña`}
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChangeText}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <LoadingButton fullWidth size="large" style={{ marginTop: 25 }} variant="contained" loading={loadingBoton} onClick={ingresar}>
                Ingresar
            </LoadingButton>
        </>
    );
}
