import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { editarNombreCortoProveedor } from '../../../services/administrador/proveedores';

FormNombreCorto.propTypes = {
    open: PropTypes.bool,
    proveedor: PropTypes.array,
    cerrarForm: PropTypes.func,
    getProveedores: PropTypes.func,
};

function FormNombreCorto({ open, cerrarForm, proveedor, getProveedores }) {
    const [form, setForm] = useState({
        id: "",
        nombre_corto: "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const editarNombreCorto = async () => {
        setLoadingBoton(true);
        
        if (!form.nombre_corto) {
            setLoadingBoton(false);
            return;
        }

        form.id = proveedor.id;

        const result = await editarNombreCortoProveedor(form);
        
        if (result.status) {
            getProveedores();
        }

        setForm({});
        setLoadingBoton(false);
        cerrarForm();

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Editar Nombre Corto
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Edita el Nombre Corto del Proveedor "{proveedor.descripcion}"
                    </DialogContentText>

                    <Grid item container justifyContent="center" mt={5}>
                        <TextField
                            id="nombre_corto"
                            name="nombre_corto"
                            label="Nombre Corto"
                            variant="outlined"
                            fullWidth
                            value={form.nombre_corto}
                            onChange={handleChangeText}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarForm}>Cancelar</Button>
                    <LoadingButton variant="contained" loading={loadingBoton} onClick={editarNombreCorto}>
                        Editar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default FormNombreCorto;
