import { Helmet } from 'react-helmet-async';
// material
import { Container } from '@material-ui/core';

// context
import useSucursal from '../../../hooks/administrador/useSucursal';

// components
import FormSucursal from './FormSucursal';

// ----------------------------------------------------------------------

export default function EditarSucursal() {
    const { sucursal } = useSucursal();
    const currentUser = sucursal;

    return (
        <>
            <Helmet>
                <title> {'Editar Sucursal'} | Scorpion </title>
            </Helmet>
        
            <Container maxWidth="xl">
                <FormSucursal sucursal={currentUser} />
            </Container>
        </>
    );
}
